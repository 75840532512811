<template>
  <a-layout id="layout">
    <!-- <AppSider></AppSider> -->
    <a-layout class="min-wid-900">
      <!-- <AppTopbar></AppTopbar> -->
      <a-layout-content class="app-contents">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
// import AppSider from '@/components/common/Sider.vue';
// import AppTopbar from '@/components/common/Topbar.vue';

export default {
  components: { AppSider, AppTopbar },
};
</script>
